// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blogpost-js": () => import("./../../../src/pages/blogpost.js" /* webpackChunkName: "component---src-pages-blogpost-js" */),
  "component---src-pages-business-js": () => import("./../../../src/pages/business.js" /* webpackChunkName: "component---src-pages-business-js" */),
  "component---src-pages-cancellation-policy-js": () => import("./../../../src/pages/cancellationPolicy.js" /* webpackChunkName: "component---src-pages-cancellation-policy-js" */),
  "component---src-pages-farmers-js": () => import("./../../../src/pages/farmers.js" /* webpackChunkName: "component---src-pages-farmers-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-loginform-js": () => import("./../../../src/pages/loginform.js" /* webpackChunkName: "component---src-pages-loginform-js" */),
  "component---src-pages-my-cart-js": () => import("./../../../src/pages/myCart.js" /* webpackChunkName: "component---src-pages-my-cart-js" */),
  "component---src-pages-order-details-js": () => import("./../../../src/pages/order_details.js" /* webpackChunkName: "component---src-pages-order-details-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-payment-checkout-js": () => import("./../../../src/pages/payment-checkout.js" /* webpackChunkName: "component---src-pages-payment-checkout-js" */),
  "component---src-pages-paymentconfirmation-js": () => import("./../../../src/pages/paymentconfirmation.js" /* webpackChunkName: "component---src-pages-paymentconfirmation-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-registration-js": () => import("./../../../src/pages/registration.js" /* webpackChunkName: "component---src-pages-registration-js" */),
  "component---src-pages-shipping-policy-js": () => import("./../../../src/pages/shipping-policy.js" /* webpackChunkName: "component---src-pages-shipping-policy-js" */),
  "component---src-pages-terms-and-condition-js": () => import("./../../../src/pages/terms-and-condition.js" /* webpackChunkName: "component---src-pages-terms-and-condition-js" */),
  "component---src-pages-work-with-us-js": () => import("./../../../src/pages/work-with-us.js" /* webpackChunkName: "component---src-pages-work-with-us-js" */),
  "component---src-pages-workshops-js": () => import("./../../../src/pages/workshops.js" /* webpackChunkName: "component---src-pages-workshops-js" */),
  "component---src-templates-nativeproducts-js": () => import("./../../../src/templates/nativeproducts.js" /* webpackChunkName: "component---src-templates-nativeproducts-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-posts-js": () => import("./../../../src/templates/posts.js" /* webpackChunkName: "component---src-templates-posts-js" */)
}

